import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import productsData from './ProductsData';
import Testimonials from "../../component/testimonials/Testimonials";
import Helps from "../../component/helps/Helps";
import KidLove from "../../component/kidLove/kidLove";
import style from "./MainShop.module.css";
import Trusted from "../../component/trusted/Trusted";
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import AwesomeSection from '../../component/awesomeSection/AwesomeSection';
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
function ProductDetail() {
  const { id } = useParams();
  const product = productsData.find(p => p.id === id);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.subblyConfig = {
          apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
          settings: {
              interceptProductLinks: true,
              cartCounterEl: '.subbly-cart-product-count',
              cartToggleEl: '.subbly-cart',
              cartButton: true,
          },
      };
    `;
    document.body.appendChild(script);

    const subblyScript = document.createElement('script');
    subblyScript.type = 'module';
    subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
    document.body.appendChild(subblyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(subblyScript);
    };
  }, []);

  if (!product) {
    return <div>Product not found!</div>;
  }

  // Filter 4 latest products (assuming 'id' or 'date' can be used to determine latest products)
  const latestProducts = productsData


    const latestUnitStudy = productsData
    .filter(product => product.category === "Digital Unit Studies")  // Filter by category
    .sort((a, b) => b.id - a.id)  // Sort by id (latest first)
    .slice(0, 4);  // Get the first 5 products
console.log(productsData
  .filter(product => product.category  === "Digital Unit Studies"),"PRODUCTdATAS")
  const removeEdventure = (animalName) => animalName.replace(/ EDventure/g, '');
  const AnimalName = removeEdventure(product.name);
  // const { id } = useParams();


  // Find the main product by ID
  const mainProduct = productsData.find(product => product.id === id);

  if (!mainProduct) {
    return ;
  }

  // Filter related products only if showUnit exists
  const relatedProducts = mainProduct.showUnit
    ? productsData.filter(
        product => product.showUnit === mainProduct.showUnit && product.id !== id
      )
    : [];

  return (
    <>
      <div className="container">
        <div className={style.productDetails}>
          <div className={`productCar ${style.productCarousel}`}>
            <div className={style.productCarouselDis}>
            <Swiper
  onSwiper={setThumbsSwiper}
  loop={true}
  spaceBetween={10}
  slidesPerView={4} // Default to 4 slides per view
  freeMode={true}
  watchSlidesProgress={true}
  modules={[Navigation, Thumbs]}
  className="mySwiper smallProduct"
  breakpoints={{
    320: { // Mobile devices
      slidesPerView: 6, // 4 slides per view for mobile
    },
    768: { // Tablet or larger
      slidesPerView: 4, // Still 4 slides per view on larger screens
    },
  }}
>
  {product.photos.map((photo, index) => (
    <SwiperSlide key={index} className={style.smallProductsDis}>
      <img src={photo} alt={product.name} />
    </SwiperSlide>
  ))}
</Swiper>

              <Swiper
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Thumbs]}
                className="bigImage"
              >
                {product.photos.map((photo, index) => (
                  <SwiperSlide key={index}>
                    <img src={photo} alt={product.name} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className={style.productContent}>
             <div className={style.productsDis}>
              
            {product.age === '3-5' ? (
              <div
                className={style.productClub}
                style={{ backgroundColor: '#D9493A' }}
              >
                {product.age === '3-5' ? (
                  <img src="/image/icon/cubb.svg" />
                ) : (
                  <img src="/image/icon/zooo.svg" />
                )}
                Ages {product.age}
              </div>
            ) : (
              <div
                className={style.productClub}
                style={{ backgroundColor: '#FF7628' }}
              >
                {product.age === '3-5' ? (
                  <img src="/image/icon/cubb.svg" />
                ) : (
                  <img src="/image/icon/zooo.svg" />
                )}
                  {product.age === '3-5' ? (
                 " Cub Club for Ages 3-5"
                ) : (
                "  Zoologist Club for Ages  5-12"
                )}
                {/* Ages {product.age} */}
              </div>
            )}
          {product.off && <span className={style.productStockTwo}>{product.off}</span>}

             </div>
            <div className={style.productType}>

              <div>
                <h1>{product.name}</h1>
                <p>{product.type}</p>
              </div>
              {product.stock && <p className={style.stock}>{product.stock}</p>}
            </div>
            
            {product.off === "30% OFF" ? (<div className={style.discountDis}>
            <h2 className={style.discountPrice}>{product.price}</h2>
            <h2>${
  Number(product.price.replace('$', '')) === 39.95
    ? '27.99'
    : (Number(product.price.replace('$', '')) * 0.7).toFixed(2)
}</h2>
            </div>)  : product.off === "50% OFF" ? (<div className={style.discountDis}>
            <h2 className={style.discountPrice}>{product.price}</h2>
            <h2>${
  Number(product.price.replace('$', '')) === 39.95
    ? '19.97'
    : (Number(product.price.replace('$', '')) * 0.7).toFixed(2)
}</h2>
            </div>) : <div className={style.discountDis}><h2>{product.price}</h2></div>}
            {/* <div className={style.discountDis}><h2>{product.price}</h2></div> */}
            <a href={product.link} className={style.cart}>
              <img src="/image/bg.svg" />
              Add To Cart
            </a>
            <div className={style.productDescription}>
              <h3>Learn all about the {AnimalName}</h3>
              <p>{product.description}</p>
            </div>
          
        {relatedProducts.map(product => (

            <>
             <h3 className={style.ZooColor}>Add more FUN and Learning with the {product.name}</h3>
           
              <div className={style.filterBoxSingle} onClick={() => animateScroll.scrollToTop()}>
              {product.off && <span className={style.productStock}>{product.off}</span>}
              {/* <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</span> */}
           <Link to={`/product/${product.id}`}><img src={product.image} alt={product.name} /></Link>
           <div className={style.priceDis}>
             <h3>{product.name}</h3>
             <p>{product.price}</p>
             <a href={product.link} className={style.boxView}>Add to Cart</a>
           </div>
            
         </div>
            </>
        ))}
     
          </div>
        </div>
      </div>
      <Trusted />

      {/* <AwesomeSection /> */}

      {/* Latest Products Section */}
      <div className={`container ${style.latestProductsSection}`}>
        <h2>Latest Products <img src='/image/heats.svg'/></h2>
        <div className={style.filterBoxDis}>
              {latestProducts.map(product => (
              
              <>
              {product.FanFavorite &&  <Link to={`/product/${product.id}` } key={product.id} className={style.filterBox}  onClick={() => animateScroll.scrollToTop()}>
              {product.off && <span className={style.productStock}>{product.off}</span>}
              <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</span>

    <img src={product.image} alt={product.name} />
     <div className={style.priceDis}>
     <h3>{product.name}</h3>
    <p>{product.price}</p>
     </div>
     <button className={style.boxView}>
      Learn More
     </button>
  </Link> }
               
              </>
          
          ))}
             </div>
      </div>



      <div className={`container ${style.latestProductsSection} ${style.digitalUnitStidy}`}>
        <h2>Digital Unit Studies <img src='/image/heats.svg'/></h2>
        <div className={style.filterBoxDis}>
        {productsData
  .filter(product => product.category === "Digital Unit Studies")
  .slice(0, 4) // Limit to the first 4 products
  .map(product => (
    <Link to={`/product/${product.id}`} key={product.id} className={style.filterBox} onClick={() => animateScroll.scrollToTop()}>
      {product.off && <span className={style.productStock}>{product.off}</span>}
      <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>
        {product.age === '3-5' ? <img src="/image/icon/cubb.svg" alt="Cubb Icon" /> : <img src="/image/icon/zooo.svg" alt="Zoo Icon" />}Ages {product.age}
      </span>
      <img src={product.image} alt={product.name} />
      <div className={style.priceDis}>
        <h3>{product.name}</h3>
        <p>{product.price}</p>
      </div>
      <button className={style.boxView}>Learn More</button>
    </Link>
  ))}

             </div>
      </div>


      {/* <h2>Related Products</h2> */}
     
    </>
  );
}

export default ProductDetail;
