import React from "react";
import styles from "./kiwiCoad.module.css";
import { Link } from "react-router-dom";

const KiwiCoAd = (props) => {

  return (
    <div className={`${styles.container} ${styles[props.data.new]}`}>
     
      <div className={styles.textSection} style={{backgroundColor:props.data.color}}>
        <img src={props.data.leaf} className={styles.leafOne}/>
        <img src={props.data.leafTwo} className={styles.leafTwo}/>
        <img src={props.data.leafThree} className={styles.leafThree}/>
      <img src={props.data.halfbg} className={styles.beiPos}/>
        <div className={styles.badge}><img src= {props.data.icon}/> <p> {props.data.name}</p></div>
        <h1 className={styles.title}>
         {props.data.title}
        </h1>
  
        <p className={styles.description}>
        {props.data.description}
          
        </p>
        <Link to={props.data.link} style={{backgroundColor:props.data.button}} className={styles.button}>Get started →</Link>
      </div>
      <div className={styles.videoSection}>
        <video
          className={styles.video}
          src={props.data.video} // Replace with the actual video file path
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
      
    </div>
  );
};

export default KiwiCoAd;
