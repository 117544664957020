import React, { useRef, useState } from 'react';
import style from "./shopV3.module.css"
import KidsTestimonials from '../../component/kidTestimonials/KidTestimonials';
import Newsletter from '../../component/newsletter/Newsletter';
import productsData from '../mainShop/ProductsData';
import { animateScroll } from "react-scroll";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination,Navigation } from 'swiper/modules';
import ProductCard from './productCardV3';
import SearchBar from './serchBarV3';
import { Link } from 'react-router-dom';
const AllShopV3 = () => {
    const [filters, setFilters] = useState({
        age: [],
        category: [],
        type: [], // Add type to filters
      });
    
      const filteredProducts = productsData.filter((product) => {
        const ageMatch = filters.age.length
          ? filters.age.includes(product.age)
          : true;
    
        const categoryMatch = filters.category.length
          ? filters.category.some((cat) => product.category.includes(cat))
          : true;
    
        const typeMatch = filters.type.length
          ? filters.type.includes(product.type) // Use product.type instead of clubType
          : true;
    
        return ageMatch && categoryMatch && typeMatch;
      });
const fun = [
    {
        image:'/image/fun.png',
        years:"3-5",
        club:"Cub",
        link:"/cub-club"
    },
    {
        image:'/image/zolo.png',
        years:"5-12",
        club:"Zoologist",
        link:"/zoo-store/all?age=5-12"
    },
]
  return (
            <>
            <div className={`container allShopV ${style.shopBanner}`}>
            <Swiper
  // pagination={true}
  navigation={true}
  pagination={{ clickable: true }}  modules={[Pagination,Navigation]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 1,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
  {/* <SwiperSlide className={style.swiperDes}>
     <Link to="/zoo-store/all?category=One-time+Boxes" >
       <img src='/image/d30.png'/>
     </Link>
  </SwiperSlide> */}
  <SwiperSlide  className={style.swiperDes}>
     <Link to="/zoo-store/all?age=5-12&category=One-time+Boxes">
       <img src='/image/swipTwo.png'/>
      </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperDes}>
     <Link to="/cub-club ">
       <img src='/image/ban3.png'/>
     </Link>
  </SwiperSlide>


  {/* <SwiperSlide className={style.swiperMob}>
     <Link to="/zoo-store/all?category=One-time+Boxes" >
       <img src='/image/30.png'/>
     </Link>
  </SwiperSlide> */}
  <SwiperSlide  className={style.swiperMob}>
  <Link to="/zoo-store/all?age=5-12&category=One-time+Boxes">
  <img src='/image/smob2.png'/>
  </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperMob}>
  <Link to="/cub-club ">
  <img src='/image/banmob.png'/>
  </Link>
  </SwiperSlide>
</Swiper>
            </div>
             <div className={style.animalFunContainer}>
             <div className={style.animalFun}>
                <h2><span>Animal fun & learning</span> for all ages</h2>
                 <div className={style.animalFunDis}>
                    {fun.map((item) =>{
                        return(
                            <Link to={item.link} className={`${style.animalFunBox} ${style[item.club]}`} onClick={() => animateScroll.scrollToTop()}>
                            <div>
                                <img src={item.image} alt='fun'/>
                                <p>{item.years} Year Olds</p>
                                <h3>Explore the <span>{item.club} Club</span> EDventures</h3>
                            </div>
                            <p className={style.funShop}>
                            Shop Now <img src='/image/r.png'/>






                            </p>
                            </Link>
                        )
                    })}
               
                 </div>
            </div>
             </div>
             <div className={style.inspire}>
             <h2>11+ Original-High Quality Resources
             to inspire young minds!</h2>
             <h3 className={style.firePopular}>🔥Popular EDventures</h3>
             <Swiper
  // pagination={true}
  modules={[Pagination]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 5,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
  {filteredProducts.map((product) => (
    <SwiperSlide key={product.id}>
      <ProductCard product={product} />
    </SwiperSlide>
  ))}
</Swiper>
<Link to="/zoo-store/all" onClick={() => animateScroll.scrollToTop()} className={style.allShop}>Shop All</Link>

             </div>
<div className={style.searchbck}>
<SearchBar productsData={productsData}/>

</div>
             <div className={style.digitalBck}>
             
                <div className={style.digitalDis}>
                  <div className={style.digitalContent}>
                      <h3>More Fun,
                      More Learning</h3>
                      <p>Dive into our Signature Digital Unit Studies!</p>
                      <Link to="/">Shop All</Link>
                  </div>
                  <div className={style.digitalSwiper}>
                  <Swiper
  // pagination={true}
  modules={[Pagination]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 4,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
{productsData
  .filter((product) => product.category === "Digital Unit Studies")
  .map((product) => (
    <SwiperSlide key={product.id}>
      <ProductCard product={product} />
    </SwiperSlide>
  ))}

</Swiper>
                  </div>
                </div>
              </div>
           
             <div className={style.foundersBck}>
             <div className='container'>
              {/* <Link to="/" className={style.didYou}>Did you Know?</Link> */}
             {/* <img src='/image/founder.png' /> */}
             <p>
              Over <span>125,000 EDventures</span> delivered across the <span>US & Canada.</span>
              
{/* Check out our <a href='https://www.facebook.com/groups/myzooboxcommunity' className={style.facebookLink}>Facebook group</a> for tons of amazing photos of our community!  */}
</p>
             </div>
              </div>
            <KidsTestimonials removeTitle="remove"/>
            <Newsletter  increase="increase" changeColor="zooColor"/>
            </>
  );
};

export default AllShopV3;
