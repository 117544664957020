import React from "react";

import style from "./Testimonials.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination,Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";
import Button from "../button/button";
const Testimonials = (props) =>{
    const group = [
        {
            image:"/image/test.webp",
            name:"Maggie Pelletier",
            describe:"Taking some time tonight to do two of the arctic fox crafts and are excited to make the cookies this weekend. She named her fox 'Salit' (dont ask why, I dont know). We're loving Zoo Boxes, so glad I stumbled upon this company.",
        },
        {
            image:"/image/test2.webp",
            name:"Sarah Goy Sirsat",
            describe:"We tried the snowball sugar cookie recipe today! They were a hit with the fox kits (we only had a red fox mask, but the Arctic friend was there for company!), but Mama Fox thinks the cookies came out a little dry and would probably decrease the flour content just a bit.",
        },
        {
            image:"/image/test3.webp",
            name:"Carissa McLain",
            describe:"I have four kids, the older ones love to do the crafts with us (11 & 14)  but the little ones (5 &7) trade off on the stuffies. So this months was my 7 year Olds turn to get the stuffie.  He loves it! Can't wait until we can start with the crafts/activities!",
        },
        {
            image:"/image/test4.webp",
            name:"Heidi Costello",
            describe:"We got our Cub Club Pangolin Box today!! He’s having a lot of fun! He combined the game with the math cards to determine how many spaces you move!",
        },
        {
            image:"/image/test5.webp",
            name:"Katie Vela",
            describe:"One of her favorite animals is the Sea Lion. She was so excited to open up this months Zoo Box to see one! We homeschool and incorporate the monthly animals into our lessons.",
        },
        {
            image:"/image/jessica.webp",
            name:"Jessica Marie Ybarra",
            describe:" Amelia's Sea Lion just came in today. She couldn't wait to get started on all the fun activities. Sara as always you and your daughter have outdone y'all selves. The creativity and details into these boxes are incredible.",
        },
        {
            image:"/image/angel.webp",
            name:"Angel Child",
            describe:" Sooo happy with our chameleons! The girls said they were so pretty!!  It has gone with us everywhere today. It’s so fun to see them look forward to opening the zoo box every month. Thank you!",
        },
        {
            image:"/image/ashle.webp",
            name:"Ashlee Gust",
            describe:" Georgia was so excited to get her bee box today!!! She named her bee Tim and showed him all the goodies that came in the box with him",
        },
        {
            image:"/image/katie.webp",
            name:"Katie Tiefenthaler",
            describe:" Absolutely loving this month's box! Taylor named her fennec fox 'geode'.",
        },
        // {
        //     image:"/image/shelby.webp",
        //     name:"Shelby Chandler Nelson",
        //     describe:"My son got his first box yesterday! He’s been attached to the stingray since opening it up…even slept with it last night this morning at breakfast he asked to read the book on coral reefs…we’re loving the zoo boxes so far!",
        // }
    ]
    const navigationOptions = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      };
    return(
        <div className={`${style.testimonials} ${style[props.deer]}`}  style={{background:props.color}}>
            {props.deer && <img src="/image/deer.png" className={style.deer} />}
            {props.deer && <img src="/image/deertwo.png" className={style.deerTwo} />}
          <div className="container">
          <h1>And so do parents!</h1>
             <Swiper
       
        spaceBetween={10}
        pagination={{   
          clickable: true,
        }}
        modules={[Navigation]}
        className="mySwiper"
        navigation={true}
        breakpoints={{
            1200:{
                slidesPerView:5,
                spaceBetween: 10,
            },
            768: {
              slidesPerView: 4, 
              spaceBetween: 0,
            },
           
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
      >
        {group.map(((item,i) =>{
            return(
                <SwiperSlide key={i}>
                <div className={style.testimonialsBox}>
                    <img src={item.image}/>
                    <div className={style.contentBox}>
                        <div className={style.verified}>
                        <h2>{item.name}</h2>
                            <img src="/image/icon/tick-circle.svg" />
                        </div>
                        
                        <p>{item.describe}</p>
                        <div className={style.stars}>
                        <img src="/image/icon/stars.svg"/>
                    </div>
                    </div>
                    
                </div>
            </SwiperSlide>
            )
        }))}
  
       
      </Swiper>
          </div>
          <div className={style.linkBox}>
             <img src="/image/fac.webp"/>
             <div>
                <h3>The My ZOO Box <br/>Facebook Community!</h3>
                <p>Join our official Facebook group to learn more about the experience of our active members!</p>
                <a href="https://www.facebook.com/groups/myzooboxcommunity" target="_blank">Join Group</a>
             </div>
          </div>
      </div>
    )
}
export default Testimonials;