import React from "react";
import FAQ from "../../component/faq/Faq";
import Trusted from "../../component/trusted/Trusted";
import Testimonials from "../../component/testimonials/Testimonials";
import KidLove from "../../component/kidLove/kidLove";
import style from "./ZooClubHoliday.module.css"
import Button from "../../component/button/button";
import Learn from "../../component/learn/Learn";
import Tabs from "../../component/tabs/Tabs";
import Helps from "../../component/helps/Helps";
import Toggle from "../../component/toggle/Toggle";
import StepToggle from "../../component/toggle/stepToggle";
import { Helmet } from "react-helmet";
import Newsletter from "../../component/newsletter/Newsletter";

const ZooClubHoliday = () =>{
  const box = [
    {
      image:"/image/vv1.png",
      title:"Stuffed Animal",
      description:"Each Zoologist Club EDventure brings a stuffy that will promote emotional development, social interaction and provide a sense of security to your little Zoologist."
  },
  {
      image:"/image/vv2.png",
      title:"Exclusive Science Book",
      description:"Provides the perfect blend of non-fiction knowledge and fun. Written by the founding team at My ZOO Box, and approved by real-life Zoologists."
  },
  {
      image:"/image/vv3.png",
      title:"Anatomy Poster & Worksheet",
      description:"Writing, art, biology are all subjects infused within the Anatomy poster & worksheep - it’s one of the most-popular items of the Zoologist Club EDventure."
  },
  {
      image:"/image/vv4.png",
      title:"STEM Project",
      description:"Science is an integral part of learning for children of this age group. Each box comes with a hands-on STEM project that revolves around the animal of the month."
  },
  {
      image:"/image/v5.png",
      title:"Themed Art Project with Supplies",
      description:"To add to the fun learning, each EDventure comes with a themed art project that adds the right amount of creativity to this learning experience."
  },
  {
      image:"/image/v6.png",
      title:"Surprise Item",
      description:"A Zoologist Club special that remains a surprise for all members. We like to keep this one a secret so that we don’t spoil the fun for our treasured community."
  },
  {
      image:"/image/vv88.png",
      title:"Postcard from the Animal",
      description:"The postcard, written from the perspective of animal, is a great way to help kids build a  connection to the animal, as well as to wildlife in general. "
  },
  {
    image:"/image/vv7.png",
    title:"Collectible Sticker",
    description:"The collectible sticker complements the Zoo Passport and makes learning about the animal kingdom an immersive experience. Each EDventure will feature a unique sticker that the little Zoologists can stick on to the passport."
},
{
  image:"/image/vv8.png",
  title:"The Zoo Passport",
  description:"Your first box will include the Zoo Passport which will turn into a memory book of all the EDventures that the little Zoologist has explored throughout their My ZOO Box journey!"
},
{
  image:"/image/v10.png",
  title:"Themed or Authentic Recipe",
  description:"We believe the kitchen is the ultimate classroom for life skills and practical math. That's why we include two delectable snack recipes in every box."
},
{
image:"/image/vv11.png",
title:"QR Printables",
description:"To add-on to the learning, each EDventure comes with additional activities to extend the learning for the curious little Zoologists."
},
]
    const faqData = [
        {
          question: 'What is Zoologist Club?',
          answer: "Zoologist Club, the ultimate zoology subscription for curious kids aged 5 and up, is here to ignite their passion for wildlife and make life easier for parents on the go.Each month, we deliver everything your child needs for thrilling zoological EDventures. Dive into captivating themes like animal camouflage, wildlife habitats, and more, as you unravel the mysteries of the natural world. Designed with young zoologists in mind, Zoologist Club offers a rich blend of science exploration, artistic expression, imaginative play, and beyond. Our hands-on projects are crafted to foster discovery, exploration, and creativity, bringing the wonders of the animal kingdom right to your doorstep. Join us on a journey of learning and excitement that both kids and parents will cherish!",
        },
        {
          question: 'How is each Zoologist Club EDventure designed?',
          answer: "At Zoologist Club, our commitment to providing your child with the best zoology subscription experience goes beyond just delivering a box of fun – it's about fostering growth and curiosity. Our EDventures are meticulously designed by a team of multi-disciplinary experts, which includes educators, child development professionals, and zoologists. Their expertise helps us ensure that each EDventure is developmentally appropriate and packed with enriching projects that spark your child's curiosity and love of learning.This design process is our commitment to you, to make sure your child receives the most innovative and exciting zoology learning month after month. With Zoologist Club, you can trust that your child's curiosity will flourish with every EDventure that arrives at your doorstep. Join us on this EDventure and watch your child's love for zoology grow! ",
        },
        {
            question: 'What concepts does Zoologist Club cover?',
            answer:"We're all about immersing kids in the fascinating world of zoology. Our Zoologist Club subscription explores the wonders of the animal kingdom through captivating, hands-on projects that are designed to spark creativity and curiosity. Each EDventure is a deep dive into enriching topics, from animal behavior and habitats to adaptation and conservation. Engage in innovative STEM projects, enjoy extention DIY activities, and learn all about the animal of the month in our exclusive books! Let your child's curiosity run wild as they embark on a thrilling zoological EDventure with us!",
          },
          {
            question: 'Do animals repeat?',
            answer:"Zoologist Club has a 5 year animal cycle, after 5 years we start the cycle over.",
          }
      ];
    const learn = 
        {
            image:"/image/boxSk.png",
            title:"Children learn best when they connect with their passion.",
            description:"Zoologist Club, designed for children who have a deep passion for animals, is committed to fostering their love for zoology and the world around them.",
            tick:"/image/icon/zootick.svg",
            options:[
                {
                    option:"Empathy, Compassion, Open-Ended Play"
                },
                {
                    option:"Basics of Biology and Physiology"
                },
                {
                    option:"Problem-Solving & Engineering"
                },
                {
                    option:"Fine Motor Skills & Creativity"
                },
                {
                    option:"Reading Comprehension, Scientific Literacy"
                }, 
                {
                  option:"Environmental Awareness"
                },
            ]
        }

        const help = [
          {
            title:"Educator-Approved Learning Activities",
            description:"Each EDventure is crafted to deliver engaging learning, introducing them to a multitude of concepts with hands-on activities and FUN!",
            image:"/image/helpzoo11.webp"
          },
          {
            class:"reverse",
            title:"Parent-approved learning materials to support early development",
            description:"As parents, it can be challenging to find quality age-appropriate learning experiences, and we are here to make it easy and convenient to discover, explore, and learn together.",
            image:"/image/helpzoo22.webp"
          }
        ]
        const kids = [
          {
            image:"/image/kidz1.webp"
          },
          {
            image:"/image/kidz2.webp"
          },
          {
            image:"/image/kidz3.webp"
          },
          {
            image:"/image/kidz4.webp"
          },
          {
            image:"/image/kidz5.webp"
          },
          {
            image:"/image/kidz6.webp"
          },
          {
            image:"/image/kidz7.webp"
          },
          {
            image:"/image/kidz8.webp"
          },
          {
            image:"/image/kidz9.webp"
          },
          {
            image:"/image/kidz10.webp"
          },
          {
            image:"/image/kidz11.webp"
          },
        ]



        
     

        
        const secondTab ={
          remove:"remove",
          headingTab:"Peek Inside a Zoologist Club EDventure",
        guide : [ {
              icon:"/image/icon/ti1.svg",
              title:"Expert Insights",
              desciption:"Discover valuable insights from experts in science and education to enhance intentional play."
          },
          {
              icon:"/image/icon/ti2.svg",
              title:"Home-Based Fun",
              desciption:"Explore simple and effective methods to keep your child engaged in educational play from the comfort of your home."
          },
          {
              icon:"/image/icon/ti3.svg",
              title:"Growth Expectations",
              desciption:"Get a better understanding of what each play kit is supposed to help progress."
          },
      ]}
  
    return(
        <>
        <Helmet>
        <title>Zoology in a Box: Wildlife Subscription Boxes for Kids</title>
        <meta name="description" content="Our Kids' Zoology Boxes are like a Behind the Scenes Zoo Adventure in a box. Perfect for animal lovers and young explorers eager to learn about the animal kingdom and conservation efforts." />
      </Helmet>
           <div className="container">
         <div className={style.banner}>
            <div>
                <img src="/image/icon/zooo.svg" className={style.cubIcon}/>
              <h1>
              The #1 Zoology
Subscription for Kids!
              </h1>
              <p>
              Fuel their love of animals and learning with STEM and life skill focused hands-on activities and FUN!
              </p>
              <div className={style.buttonDis}>
              <Button dynamicClass="fillColor" text="Get Started" link="/zoologist-plan"/>
              <Button dynamicClass="borderColor" text="Gift it" yellow="yellow" link="/gifting-zoologist-club"/>
              </div>
              <img src="/image/fox.png" className={style.zooPos} />
            </div>
            <div>
                <img src="/image/zooban2.webp"/>
        
            </div>
         </div>
     
        </div>
      <div className="container">
       <div className={style.learnZoo}>
        <img src="/image/bell.png" className={style.bell}/>
        <img src="/image/fal.png" className={style.fal1}/>
        <img src="/image/fal.png" className={style.fal2}/>
        <img src="/image/fal.png" className={style.fal3}/>
        <img src="/image/fal.png" className={style.fal4}/>
       <Learn image={learn.image} text="Get Started" title={learn.title} description={learn.description} tick={learn.tick} options={learn.options} color="fillZooColor"  link="/zoologist-plan"/>
       </div>
      <div className={style.tabsZoo}>
      <img src="/image/bell2.png" className={style.bell2}/>
        <img src="/image/fal.png" className={style.fal11}/>
        <img src="/image/fal.png" className={style.fal22}/>
        <img src="/image/fal.png" className={style.fal33}/>
        <img src="/image/fal.png" className={style.fal44}/>
        <img src="/image/fal.png" className={style.fal55}/>
        <img src="/image/fal.png" className={style.fal66}/>
        <img src="/image/fal.png" className={style.fal77  }/>
      <Tabs box={box} boxTwo={secondTab}/>
      </div>

      
    
      </div>
       <div className={style.trees}>
        <img src="/image/gift1.png" className={style.gift1}/>
        <img src="/image/gift2.png" className={style.gift2}/>
        <img src="/image/gift3.png" className={style.gift3}/>

        <img src="/image/gift4.png" className={style.gift4}/>


       <Helps zooTitle="zooTitle" title={
  <>
    How <span className={style.zooTitle}>Zoologist Club</span> helps 5+ year old children learn
  </>
} box={help} />
       </div>
        <KidLove kidBck="snowbck" color="#ea5443" headerColor="white" kid={kids} title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/>
        <Testimonials  deer="deerBck"/>
 
        {/* <StepToggle box={toggleMonthly} heading={heading} boxTwo={togglePrepaid} prepaid="prepaidDis" remove="" /> */}

        <Trusted/>
        <div className="pt50 pb50 subContainer">
           <FAQ data={faqData}/>
        </div>
        <Newsletter/>
        </>
    )
}

export default ZooClubHoliday;