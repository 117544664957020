import React from "react";
import style from "./Helps.module.css"
const Helps = (props) =>{
    return(
        <div className={`pt50 pb50 ${style.helps}`}>
           <div className="container">
           <h1 className={style[props.zooTitle]}>{props.title}</h1>
            <div className={`pt50 ${style.helpsDis}`}>
                {props.box.map(((item,i) =>{
                    return(
                        <div className={`${style.helpsBox} ${style[item.class]}`} key={i}>
                        <div className={style.helpsContent}>
                             <h2>{item.title}</h2>
                             <p>{item.description}</p>
                         </div>
                         <div>
                             <img src={item.image}/>
                         </div>
                        </div>
                       
                    )
                }))}
              
            
            </div>
           </div>
        </div>
    )
}

export default Helps