import React from "react";
import style from "./kidLove.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination ,Navigation,Autoplay} from 'swiper/modules';

const KidLove = (props) =>{
    return(
        <div className={` pb50 ${style.kidBck} ${style[props.kidBck]}`} style={{background:props.color}}>
        <div className="container">
        <h1  style={{color:props.headerColor}}>{props.title}</h1>
        {props.titleTwo ? <p>Over 100,000 boxes shipped globally...and counting!</p> : "" }
            
        </div>
            <div className={`pt50 ${style.kidSwiper}`}>
       <Swiper
        slidesPerView={6.7}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,Autoplay]}
        autoplay={{ // Configure autoplay options
          delay: 4000, // Autoplay delay in milliseconds
          disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
        }}
        navigation={true}
        className="mySwiper"
        loop={true}
        
        breakpoints={{
          1200:{
            slidesPerView:6.7,
            spaceBetween: 10,
        },
          768: {
            slidesPerView: 4, 
            spaceBetween: 0,
          },
         
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          
        }}
        
      >
        {props.kid.map(((item,i) =>{
          return(
              <SwiperSlide key={i} className={style.mobBox}><img src={item.image}/></SwiperSlide>
          )
        }))}
  

      </Swiper>
            </div>
        </div>
    )
}

export default KidLove;